import React from "react"
import { useIntl, FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"
import Seo from "../components/SEO"
import Header from "../components/HeaderC"
import PageNav from "../components/PageNav"

const Biography = (props) => {

  const intl = useIntl()

  return (
    <div>
      <Seo
        title={intl.formatMessage({ id: "biography.title.seo" })}
      />
      <Header />

      <div className="sir-breadcrumbs">
        <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
        <span class="text-sir-black-25 mx-1">/</span>
        <FormattedMessage id="global.menu.biography" />
      </div>

      <div className="sir-section">
        <div className="sir-layout-2-3 text-center">
          <h2 className="title-l mb-8 uppercase">
            <FormattedMessage id="biography.title" />
          </h2>
        </div>

        <div className="sir-layout-text">
          <p><FormattedMessage id="biography.bio.p1" /></p>
          <p><FormattedMessage id="biography.bio.p2" /></p>
          <p><FormattedMessage id="biography.bio.p3" /></p>
          <p><FormattedMessage id="biography.bio.p4" /></p>
          <p><FormattedMessage id="biography.bio.p5" /></p>
          <p><FormattedMessage id="biography.bio.p6" /></p>
          <p className="body-s"><FormattedMessage id="biography.bio.p7" /></p>
          <div className="border-b border-black w-1/3 mb-4"></div>
          <p className="body-s"><FormattedHTMLMessage id="biography.bio.src" /></p>
        </div>
      </div>



      <PageNav />
    </div>
  )
}

export default Biography;